import {useEffect, useState} from "react";
import { Container } from '../layout/container';
import Logo  from '../../resource/images/logo.png'
import {FooterNavLinks} from "../common/footer-nav-links";
import Logos from '../../resource/images/loghi.png'

function QrCodeBorder(props) {
    return (
        <svg viewBox="0 0 96 96" fill="none" aria-hidden="true" {...props}>
            <path
                d="M1 17V9a8 8 0 0 1 8-8h8M95 17V9a8 8 0 0 0-8-8h-8M1 79v8a8 8 0 0 0 8 8h8M95 79v8a8 8 0 0 1-8 8h-8"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    )
}

export function Footer({Storyblok, fixed = false}) {


    const [pageDetail, setPageDetail] = useState(false);

    useEffect(() => {
        Storyblok.get(`cdn/stories/footer`)
            .then(response => {
                setPageDetail(response.data.story);
            }).catch(error => {
            console.error('Error fetching story:', error);
        });

    }, [Storyblok]);



    return (
        <footer className={fixed ? "border-t border-gray-200 bg-white" : "border-t border-gray-200"}>
            <Container>
                <div className="flex flex-col items-start justify-between gap-y-12 pb-6 pt-16 lg:flex-row lg:items-center lg:py-16">
                    <div>
                        <div className="flex items-center text-gray-900">
                            <img src={Logo} alt="Logo Minigoal" className=" w-28 flex-none fill-cyan-500" />
                        </div>
                        <nav className="mt-11 flex gap-8">
                            <FooterNavLinks />
                        </nav>
                    </div>
                    <div className="group relative -mx-4 flex items-center self-stretch p-4 transition-colors hover:bg-gray-100 sm:self-auto sm:rounded-2xl lg:mx-0 lg:self-auto lg:p-6">
                        <div className="relative flex h-24 w-24 flex-none items-center justify-center">
                            <QrCodeBorder className="absolute inset-0 h-full w-full stroke-gray-300 transition-colors group-hover:stroke-red-600" />
                            <img src={pageDetail?.content?.contenuto[0]?.qr_code} className="p-2" alt="" />
                        </div>
                        <div className="ml-8 lg:w-64">
                            <p className="text-base font-semibold text-gray-900">
                                <span className="absolute inset-0 sm:rounded-2xl" />
                                {pageDetail?.content?.contenuto[0]?.download_title}
                            </p>
                            <p className="mt-1 text-sm text-gray-700">
                                {pageDetail?.content?.contenuto[0]?.download_description}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center border-t border-gray-200 pb-12 pt-8 md:flex-row md:justify-between md:pt-6">
                    <p className="mt-6 text-sm text-gray-500 md:mt-0">
                        &copy; Copyright {new Date().getFullYear()}. Tutti i diritti riservati. {pageDetail?.content?.contenuto[0]?.footer_copyright}
                    </p>

                    <p className="mt-6 text-sm text-gray-500 md:mt-0">
                        Crafted with love by <a href={"https://www.skemasoft.com/"} className={"underline"}>Skemasoft.com</a>.
                    </p>
                </div>
            </Container>


            <img src={Logos} alt="Logo Minigoal" className=" w-[500px] mx-auto py-4" />
        </footer>
    )
}

import {useEffect, useState} from "react";
import Header from "../../components/layout/header";
import {Navigation} from "../../components/layout/navigation";
import {CallToAction} from "../../components/common/call-to-action";
import {PrimaryFeatures} from "../../components/common/primary-features";
import {SecondaryFeatures} from "../../components/common/secondary-features";
import {Gallery} from "../../components/common/gallery";
import {Faqs} from "../../components/common/faqs";
import {Footer} from "../../components/layout/footer";
import ContactUs from "../../components/common/contact-us";
import Loading from "../../components/common/loading";
import {useLocation} from "react-router-dom";
import Cookie from "../../components/common/cookie";
import AdsPopup from "../../components/common/ads-popup";
import NewsPopup from "../../components/common/news-popup";
import { Adsense } from "@ctrl/react-adsense";


const Homepage = ({Storyblok}) => {

    const [pageDetail, setPageDetail] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
        if(!pageDetail){
            Storyblok.get(`cdn/stories/home-page/home`)
                .then(response => {
                    setPageDetail(response.data.story);
                    setLoading(false);
                }).catch(error => {
                console.error('Error fetching story:', error);
            });
        }
    }, [Storyblok, pageDetail]);

    useEffect(() => {
        const { hash } = location;

        if (hash && !isLoading) {
            const element = document.getElementById(hash.substring(1));

            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location, isLoading]);

   if(isLoading){
       return (<><Loading /></>);
   }


  return (
      <div className="bg-gray-100 scroll-smooth">
          <Navigation/>
          <Header
              pageDetail={pageDetail?.content.contenuto?.filter(r => r.component === 'Hero')[0] ?? []}
              logos={pageDetail?.content.contenuto?.filter(r => r.component === 'Partners')[0] ?? []}
          />

          <div className="mx-auto max-w-7xl my-2 mb-6 text-center">
              <Adsense
                  client="ca-pub-7934619462197808"
                  slot="1270255267"
                  style={{ display: 'block' }}
                  adTest="true"
                  responsive="true"
                  format="auto"
              />
          </div>
          <NewsPopup Storyblok={Storyblok} />

          <div className="mx-auto max-w-7xl my-2 mb-6 text-center">
              <Adsense
                  client="ca-pub-7934619462197808"
                  slot="4114305791"
                  style={{ display: 'block' }}
                  adTest="true"
                  responsive="true"
                  format="autorelaxed"
              />
          </div>

          <CallToAction pageDetail={pageDetail?.content.contenuto?.filter(r => r.component === 'Ultimo Minigoal')[0] ?? []} />
          <PrimaryFeatures pageDetail={pageDetail?.content.contenuto?.filter(r => r.component === 'App')[0] ?? []} />


          <SecondaryFeatures  pageDetail={pageDetail?.content.contenuto?.filter(r => r.component === 'Chi Siamo')[0] ?? []} />
          <Gallery pageDetail={pageDetail?.content.contenuto?.filter(r => r.component === 'gallery')[0] ?? []} />
          <ContactUs pageDetail={pageDetail?.content.contenuto?.filter(r => r.component === 'Contatti')[0] ?? []} />
          <Faqs title={pageDetail?.content.contenuto?.filter(r => r.component === 'faq_detail')[0] ?? []} pageDetail={pageDetail?.content.contenuto?.filter(r => r.component === 'faq')[0] ?? []} />
          <Footer Storyblok={Storyblok} />
          <Cookie />

          <AdsPopup Storyblok={Storyblok} />


      </div>
  );
}


export default Homepage;

import { useId } from 'react'
import {Container} from "./container";
import {AppStoreLink} from "../common/app-store-link";
import bg from "../../resource/images/web.png";
import {AndroidStoreLink} from "../common/android-store-link";




function BackgroundIllustration(props) {
    let id = useId()

    return (
        <div {...props}>
            <svg
                viewBox="0 0 1026 1026"
                fill="none"
                aria-hidden="true"
                className="absolute inset-0 h-full w-full animate-spin-slow"
            >
                <path
                    d="M1025 513c0 282.77-229.23 512-512 512S1 795.77 1 513 230.23 1 513 1s512 229.23 512 512Z"
                    stroke="#D4D4D4"
                    strokeOpacity="0.7"
                />
                <path
                    d="M513 1025C230.23 1025 1 795.77 1 513"
                    stroke={`url(#${id}-gradient-1)`}
                    strokeLinecap="round"
                />
                <defs>
                    <linearGradient
                        id={`${id}-gradient-1`}
                        x1="1"
                        y1="513"
                        x2="1"
                        y2="1025"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#db2725" />
                        <stop offset="1" stopColor="#db2725" stopOpacity="0" />
                    </linearGradient>
                </defs>
            </svg>
            <svg
                viewBox="0 0 1026 1026"
                fill="none"
                aria-hidden="true"
                className="absolute inset-0 h-full w-full animate-spin-reverse-slower"
            >
                <path
                    d="M913 513c0 220.914-179.086 400-400 400S113 733.914 113 513s179.086-400 400-400 400 179.086 400 400Z"
                    stroke="#D4D4D4"
                    strokeOpacity="0.7"
                />
                <path
                    d="M913 513c0 220.914-179.086 400-400 400"
                    stroke={`url(#${id}-gradient-2)`}
                    strokeLinecap="round"
                />
                <defs>
                    <linearGradient
                        id={`${id}-gradient-2`}
                        x1="913"
                        y1="513"
                        x2="913"
                        y2="913"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#db2725" />
                        <stop offset="1" stopColor="#db2725" stopOpacity="0" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    )
}

export default function Header({pageDetail, logos}) {


    return (



        <div className="overflow-hidden relative isolate py-20 ">

            <Container>
                <div className="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
                    <div className="relative z-10 mx-auto max-w-2xl lg:col-span-7 lg:max-w-none lg:pt-6 xl:col-span-6">
                        <h1 className="text-3xl font-semibold tracking-tight text-gray-900">
                            {pageDetail.title}
                        </h1>
                        <p className="mt-6 text-lg text-gray-600">
                            {pageDetail.description}
                        </p>
                        <div className="mt-8 flex flex-wrap gap-x-6 gap-y-4">
                            {
                                pageDetail.ios_link && (
                                    <AppStoreLink link={pageDetail.ios_link} />
                                )
                            }

                            {
                                pageDetail.android_link && (
                                    <AndroidStoreLink link={pageDetail.android_link} />
                                )
                            }
                        </div>
                    </div>

                    <div className="relative mt-10 sm:mt-20 lg:col-span-5  lg:mt-0 xl:col-span-6">
                        <BackgroundIllustration className="absolute left-1/2 top-4 h-[1026px] w-[1026px] -translate-x-1/3 stroke-gray-300/70 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)] sm:top-16 sm:-translate-x-1/2 lg:-top-16 lg:ml-12 xl:-top-14 xl:ml-0" />

                        <div className="-mx-4 lg:h-[550px] px-9 [mask-image:linear-gradient(to_bottom,white_60%,transparent)] sm:mx-0 lg:absolute lg:-inset-x-10 lg:-bottom-20 lg:-top-10 lg:h-auto lg:px-0 lg:pt-10 xl:-bottom-32 ">
                            <img src={bg} className=" mx-auto" alt=""/>
                        </div>

                    </div>

                    <div className="relative -mt-4 lg:col-span-12 lg:mt-0 xl:col-span-12">
                        <p className="text-center text-sm font-semibold text-gray-900 lg:text-left uppercase">
                            I nostri partners
                        </p>
                        <ul
                            className="mx-auto mt-8 flex  flex-wrap justify-center gap-x-10 gap-y-8 lg:mx-0 lg:justify-start"
                        >
                            {
                                (logos?.partners_logo ?? []).map(logo => (
                                <li key={logo.id} className={'flex'}>
                                    <a href={logo?.source ?? '#'} target="_blank" rel="noreferrer">
                                        <img src={logo.filename} alt={''} className={logo.alt === 'grande' ? "h-14" :"h-10"} />
                                    </a>
                                </li>
                            ))
                            }
                        </ul>
                    </div>
                </div>
            </Container>
        </div>

    )
}

